import React, { useEffect } from 'react';

const RedirectComponent = () => {
    useEffect(() => {
        window.location.href = 'https://mestoria-online.vercel.app/p'; // Замени на нужную ссылку
    }, []);

    return null; // Можно вернуть что-то другое, если нужно
};

export default RedirectComponent;